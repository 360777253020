namespace CONSTANTS {
    export enum SupportedLanguage {
        FR = 'FR',
        EN = 'EN',
        IT = 'IT',
        ES = 'ES',
    }

    export const LOCAL_STORAGE_KEYS = {
        ACCESS_TOKEN: 'accessToken',
        REFRESH_TOKEN: 'refreshToken',
    };

    export enum SCREEN {
        HOME = 'HOME',
        PRODUCT_DETAIL = 'PRODUCT_DETAIL',
        CATEGORY = 'CATEGORY',
        SUB_CATEGORY = 'SUB_CATEGORY',
        LOGIN = 'LOGIN',
    }

    export enum CATEGORY_ID {
        WOMEN = 'women',
        MEN = 'men',
    }

    export const API_URL = {
        DEV: 'https://api.dev.spreeai.com/',
        STAGE: 'https://api.stage.spreeai.com/',
        PROD: 'https://api.spreeai.com/',
    };

    export const MANAGE_ACCOUNT_URL = {
        DEV: 'https://dev.spreeai.com/login',
        STAGE: 'https://stage.spreeai.com/login',
        PROD: 'https://spreeai.com/login',
    };

    export const FORGOT_PASSWORD_URL = {
        DEV: 'https://dev.spreeai.com/login?action=forgotPassword',
        STAGE: 'https://stage.spreeai.com/login?action=forgotPassword',
        PROD: 'https://spreeai.com/login?action=forgotPassword',
    };

    export const CREATE_ACCOUNT_URL = {
        DEV: 'https://dev.spreeai.com/create-account',
        STAGE: 'https://stage.spreeai.com/create-account',
        PROD: 'https://spreeai.com/create-account',
    };

    // TODO: Change this when SpreeAi deploys their dev and prod SDK
    export const BRIDGE_URL = {
        DEV: 'https://dev.bridge.spree.appcom.ca',
        STAGE: 'https://dev.bridge.spree.appcom.ca',
        PROD: 'https://dev.bridge.spree.appcom.ca',
    };

    export const ROUTES = {
        EN: {
            HOME: '/',
            PRODUCT_DETAIL: '/product/:id',
            CATEGORY: '/collections/:category',
            SUB_CATEGORY: '/collections/:category/:subCategory',
            LOGIN: '/auth',
        },
        FR: {
            HOME: '/fr',
            PRODUCT_DETAIL: '/fr/product/:id',
            CATEGORY: '/collections/fr/:category',
            SUB_CATEGORY: '/collections/fr/:category/:subCategory',
            LOGIN: '/fr/auth',
        },
        IT: {
            HOME: '/it',
            PRODUCT_DETAIL: '/it/product/:id',
            CATEGORY: '/collections/it/:category',
            SUB_CATEGORY: '/collections/it/:category/:subCategory',
            LOGIN: '/it/auth',
        },
        ES: {
            HOME: '/es',
            PRODUCT_DETAIL: '/es/product/:id',
            CATEGORY: '/es/:category',
            SUB_CATEGORY: '/collections/es/:category/:subCategory',
            LOGIN: '/es/auth',
        },
    };
}

export default CONSTANTS;

export function getKeyByValueRecursive(object: any, value: any) {
    for (const key in object) {
        if (typeof object[key] === 'object') {
            const nestedKey: any = getKeyByValueRecursive(object[key], value);
            if (nestedKey !== null) {
                return key + '.' + nestedKey;
            }
        } else if (object[key] === value) {
            return key;
        }
    }
    return null;
}

import { Route, Routes } from 'react-router-dom';
import { ROUTER_CONFIG, ROUTE } from './config';
import CONSTANTS from '../Constants';
import { useStores } from '../Hooks';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { getKeyByValueRecursive } from '../Utils/object';
import useCustomNavigation from '../Hooks/use-custom-navigation';
import { DefaultLayout } from '../Layout';
import useAuthRedirect from '../Hooks/use-auth-redirect';

const Router = observer(() => {
    const { languageStore } = useStores();
    const customNavigate = useCustomNavigation();

    useAuthRedirect();

    const EnsureRightLanguage = () => {
        try {
            const path = window.location.pathname;
            const keyRoutesOfPath = getKeyByValueRecursive(CONSTANTS.ROUTES, path);

            if (
                keyRoutesOfPath?.split('.')?.[0]?.includes((languageStore as any).currentLanguage)
            ) {
                // User is in the right language
                return;
            }

            if (!keyRoutesOfPath) {
                throw new Error('No key found for this path');
            }

            const lastKey = keyRoutesOfPath.split('.').pop();
            if (!lastKey) {
                throw new Error('Internal error');
            }

            const newPath = (CONSTANTS.ROUTES as any)[(languageStore as any).currentLanguage][
                lastKey
            ];
            if (!newPath) {
                throw new Error('Any path found');
            }

            customNavigate(newPath);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (window.location.pathname && languageStore.currentLanguage != null) {
            EnsureRightLanguage();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname, languageStore.currentLanguage]);

    return (
        <Routes>
            {Object.keys(CONSTANTS.ROUTES).map((language) => {
                return ROUTER_CONFIG.map((route: ROUTE) => {
                    const path = (CONSTANTS.ROUTES as any)[language][route.path];

                    return (
                        <Route
                            key={path || route.path}
                            path={path || route.path}
                            Component={() => {
                                const Content = route.children;

                                if (route.noLayout) {
                                    return <Content />;
                                }

                                return (
                                    <DefaultLayout
                                        options={
                                            (route.seo as any)?.[
                                                languageStore.currentLanguage?.toLocaleLowerCase() ||
                                                    'en'
                                            ]
                                        }
                                    >
                                        <Content />
                                    </DefaultLayout>
                                );
                            }}
                        />
                    );
                });
            })}
        </Routes>
    );
});

export default Router;

import api from '../Utils/api';

export interface AuthDTO {
    request_id: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    user_id: string;
}

const authService = {
    loginUser: async (email: string, password: string): Promise<AuthDTO> => {
        return api.post(`/v1/auth/login`, { email, password });
    },
};

export default authService;

import { FC, MouseEventHandler, ReactNode } from 'react';
import './index.scss';

type ButtonType = 'primary' | 'secondary' | 'text' | 'danger' | 'success';

interface ButtonProps {
    children: ReactNode;
    type: ButtonType;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Button: FC<ButtonProps> = ({ children, type, onClick, className }) => {
    const buttonClassName = `Button Button--${type} ${className || ''}`;

    return (
        <button onClick={onClick} className={buttonClassName}>
            {children}
        </button>
    );
};

export default Button;

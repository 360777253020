import React from 'react';
import styles from './index.module.scss';

interface RadioButtonProps {
    id: string;
    name: string;
    value: string;
    checked: boolean;
    onChange: (value: string) => void;
    label: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({ id, name, value, checked, onChange, label }) => {
    return (
        <div className={styles.radioButton}>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={() => onChange(value)}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default RadioButton;

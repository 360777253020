import { FC } from 'react';
import styles from './index.module.scss';

const SkeletonCard: FC = () => {
    return (
        <div className={styles.skeletonCard}>
            <div className={styles.skeletonImage}></div>
            <div className={styles.skeletonInfo}>
                <div className={styles.skeletonTitle}></div>
                <div className={styles.skeletonSubcategory}></div>
            </div>
        </div>
    );
};

export default SkeletonCard;

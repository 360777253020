import { FC } from 'react';
import { SvgProps } from './SvgProps';

const AssetsIcon: FC<SvgProps> = ({ ...props }) => {
    return (
        <svg width="24" height="40" viewBox="0 0 40 40" fill="none" {...props}>
            <path
                d="M3.97317 22.9884C3.22979 17.7158 2.8581 15.0795 4.43785 13.3731C6.0176 11.6667 8.82994 11.6667 14.4546 11.6667H25.5467C31.1714 11.6667 33.9837 11.6667 35.5635 13.3731C37.1432 15.0795 36.7715 17.7158 36.0281 22.9884L35.3232 27.9884C34.7402 32.1232 34.4487 34.1906 32.9534 35.4287C31.4581 36.6667 29.2526 36.6667 24.8417 36.6667H15.1596C10.7487 36.6667 8.5432 36.6667 7.04788 35.4287C5.55257 34.1906 5.26108 32.1232 4.67812 27.9884L3.97317 22.9884Z"
                stroke={props.stroke}
                strokeWidth="2"
            ></path>
            <path
                d="M32.6026 11.6667C32.9836 9.49205 31.3102 7.5 29.1025 7.5H10.8961C8.68839 7.5 7.01504 9.49205 7.39608 11.6667"
                stroke={props.stroke}
                strokeWidth="2"
            ></path>
            <path
                d="M29.1666 7.49992C29.2139 7.06812 29.2375 6.85217 29.2379 6.67383C29.2416 4.96779 27.9565 3.53432 26.2602 3.35228C26.0829 3.33325 25.8657 3.33325 25.4313 3.33325H14.5683C14.1339 3.33325 13.9167 3.33325 13.7394 3.35228C12.0431 3.53432 10.758 4.96779 10.7617 6.67383C10.7621 6.85217 10.7858 7.06809 10.8331 7.49992"
                stroke={props.stroke}
                strokeWidth="2"
            ></path>
            <circle cx="27.5" cy="19.1667" r="2.5" stroke={props.fill} strokeWidth="2"></circle>
            <path
                d="M33.3341 33.3333L28.5271 29.7523C26.9769 28.5975 24.6684 28.4825 22.962 29.4751L22.5174 29.7338C21.3316 30.4235 19.7184 30.3079 18.6936 29.4596L12.2966 24.1648C11.0198 23.1079 8.97172 23.0515 7.61281 24.0357L5.40625 25.6337"
                stroke={props.stroke}
                strokeWidth="2"
                strokeLinecap="round"
            ></path>
        </svg>
    );
};

export default AssetsIcon;

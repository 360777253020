import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductCard from '../../Components/ProductCard';
import CONSTANTS from '../../Constants';
import { useCustomNavigation } from '../../Hooks';
import { garmentService } from '../../Services';
import './index.scss';
import Skeleton from '../../Components/Skeleton';
import BreadCrumb from '../../Components/BreadCrumbs';

const ProductDetail = () => {
    const { id } = useParams();
    const nav = useCustomNavigation();

    const { data } = useQuery({
        queryKey: ['productDetail'],
        queryFn: () => garmentService.fetchGarment(id as string),
    });

    useEffect(() => {
        if (!id) {
            nav(CONSTANTS.SCREEN.HOME);
        }
        //eslint-disable-next-line
    }, [id]);

    return (
        <>
            <BreadCrumb productData={data} className="container" />
            {data ? <ProductCard garment={data} /> : <Skeleton />}
        </>
    );
};

export default ProductDetail;

import * as yup from 'yup';

export const LoginSchema = yup
    .object({
        email: yup
            .string()
            .email('validation.invalid_email')
            .max(256, 'validation.email_max_length')
            .required('validation.email_required'),
        password: yup
            .string()
            .min(8, 'validation.password_min_length')
            .max(256, 'validation.password_max_length')
            .required('validation.password_required'),
    })
    .required();

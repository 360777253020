export const getEnvironment = (): 'DEV' | 'STAGE' | 'PROD' => {
    const hostname = window.location.hostname;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
        return 'DEV';
    }
    if (hostname.includes('stage.') || hostname.includes('staging.')) {
        return 'STAGE';
    }
    return 'PROD';
};

export const getUrlForEnvironment = (urls: {
    DEV: string;
    STAGE: string;
    PROD: string;
}): string => {
    const env = getEnvironment();
    return urls[env];
};

import { FC } from 'react';
import { SvgProps } from './SvgProps';

const ProteaIcon: FC<SvgProps> = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 140 140"
            fill="none"
            {...props}
        >
            <g filter="url(#filter0_d_151_2)">
                <circle cx="70" cy="68" r="50" fill="#50C878"></circle>
                <circle cx="70" cy="68" r="50" fill="#50C878"></circle>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M78.5898 45.9557L58.7216 76.2716C62.0749 83.8395 69.6524 89.1189 78.4628 89.1189C90.3821 89.1189 100.045 79.4564 100.045 67.5372C100.045 64.5585 99.4418 61.7232 98.3523 59.1447C98.1422 58.6473 98.3751 58.0737 98.8724 57.8636C99.3698 57.6534 99.9434 57.8863 100.154 58.3837C101.343 61.1987 102 64.2924 102 67.5372C102 80.5364 91.4621 91.0743 78.4628 91.0743C69.2963 91.0743 61.3536 85.8343 57.4668 78.1862L50.2197 89.2442C49.9238 89.6958 49.3177 89.822 48.8661 89.526C48.4145 89.23 48.2883 88.624 48.5843 88.1724L56.5185 76.066C55.49 73.4215 54.9257 70.5452 54.9257 67.5372C54.9257 66.2862 55.0234 65.0578 55.2115 63.8592L39.7955 87.3817C39.4995 87.8333 38.8935 87.9595 38.4419 87.6635C37.9903 87.3675 37.8641 86.7615 38.1601 86.3099L57.4821 56.8274C57.778 56.3758 58.3841 56.2496 58.8357 56.5456C59.2873 56.8416 59.4135 57.4476 59.1175 57.8992L59.0767 57.9615C59.0613 58.0446 59.0347 58.127 58.9964 58.2067C57.6409 61.0295 56.8811 64.1932 56.8811 67.5372C56.8811 69.7913 57.2266 71.9647 57.8677 74.0073L77.244 44.4419C77.4709 44.0957 77.8801 43.9407 78.2622 44.0206C78.3269 44.0071 78.3941 44 78.4628 44C78.6169 44 78.7706 44.0015 78.924 44.0044C85.9587 44.1397 92.2377 47.362 96.4615 52.3691C96.8097 52.7819 96.7573 53.3987 96.3446 53.7468C95.9319 54.095 95.3151 54.0426 94.9669 53.6299C91.091 49.0352 85.335 46.0835 78.8864 45.9594C78.7877 45.9575 78.6888 45.9563 78.5898 45.9557Z"
                    fill="white"
                ></path>
            </g>
            <defs>
                <filter
                    id="filter0_d_151_2"
                    x="0"
                    y="0"
                    width="140"
                    height="140"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    ></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    ></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_151_2"
                    ></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_151_2"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
};

export default ProteaIcon;

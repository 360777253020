import { useEffect, useState } from 'react';
import { GarmentDTO } from '../../Services/garmentService';
import ProductCarousel from '../ProductCarousel';
import styles from './index.module.scss';
import RadioButton from '../RadioButton';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks';
import { getUrlForEnvironment } from '../../Utils/environment';
import CONSTANTS from '../../Constants';

interface ProductCardProps {
    garment: GarmentDTO;
}

const IFRAME_EXIT_CODE = '00001';

const ProductCard: React.FC<ProductCardProps> = ({ garment }) => {
    const [selectedColor, setSelectedColor] = useState(garment.variants[0].color);
    const [selectedSize, setSelectedSize] = useState(garment.variants[0].size);
    const [showIframe, setShowIframe] = useState(false);
    const { t } = useTranslation();
    const { languageStore } = useStores();

    const getTryAccountUrl = () => getUrlForEnvironment(CONSTANTS.BRIDGE_URL);

    const filteredVariants = garment.variants.filter((variant) => variant.color === selectedColor);
    const selectedVariant = filteredVariants.find((variant) => variant.size === selectedSize);
    const [price, setPrice] = useState(selectedVariant ? selectedVariant.price : 0);

    const handleColorChange = (color: string) => {
        setSelectedColor(color);
        const newVariant = garment.variants.find(
            (variant) => variant.color === color && variant.size === selectedSize
        );
        setPrice(newVariant ? newVariant.price : 0);
    };

    const handleSizeChange = (size: string) => {
        setSelectedSize(size);
        const newVariant = filteredVariants.find((variant) => variant.size === size);
        setPrice(newVariant ? newVariant.price : 0);
    };

    const handleTryOn = () => {
        setShowIframe(true);
        document.body.classList.add('no-scroll');
    };

    const handleIframeMessage = (event: any) => {
        if (event.data === IFRAME_EXIT_CODE) {
            setShowIframe(false);
            document.body.classList.remove('no-scroll');
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleIframeMessage);

        return () => window.removeEventListener('message', handleIframeMessage);
    }, []);

    return (
        <div className={styles.product}>
            <div className={`${styles.product__container} container`}>
                <ProductCarousel images={garment.images} onClickTryOn={handleTryOn} />
                <div className={styles.product__details}>
                    <h2 className={`${styles.product__title} text-heading`}>{garment.title}</h2>
                    <span className={styles.product__price}>${price}</span>
                    <div className={styles.product__options}>
                        <label className={`${styles.product__label} text-label`}>
                            {t('productDetail.color')}
                        </label>
                        <div className={styles.product__sizeButtonsContainer}>
                            {Array.from(
                                new Set(garment.variants.map((variant) => variant.color))
                            ).map((color) => (
                                <RadioButton
                                    key={color}
                                    id={color}
                                    name="color"
                                    value={color}
                                    checked={selectedColor === color}
                                    onChange={handleColorChange}
                                    label={color}
                                />
                            ))}
                        </div>

                        <label className={`${styles.product__label} text-label`}>
                            {t('productDetail.size')}
                        </label>
                        <div className={styles.product__sizeButtonsContainer}>
                            {filteredVariants.map((variant) => (
                                <RadioButton
                                    key={variant.size}
                                    id={variant.size}
                                    name="size"
                                    value={variant.size}
                                    checked={selectedSize === variant.size}
                                    onChange={handleSizeChange}
                                    label={variant.size}
                                />
                            ))}
                        </div>

                        <div className={styles.product__productDetails}>
                            <p>{t('productDetail.productDetail')}</p>
                            <p className={styles.product__description}>{garment.description}</p>
                            <div className={styles.product__circle} />
                        </div>
                    </div>
                </div>
            </div>
            {showIframe && (
                <div className={styles.iframeContainer}>
                    <iframe
                        className={styles.iframe}
                        title="Try On"
                        src={`${getTryAccountUrl()}/?garmentId=${garment.id}&partnerId=protea&lng=${languageStore.currentLanguage}`}
                        sandbox="allow-scripts allow-forms allow-same-origin allow-top-navigation allow-popups"
                        allow="web-share"
                    />
                </div>
            )}
        </div>
    );
};

export default ProductCard;

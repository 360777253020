import { useNavigate } from 'react-router-dom';
import useStores from './use-stores';
import CONSTANTS from '../Constants';

const useCustomNavigation = () => {
    const navigation = useNavigate();
    const { languageStore } = useStores();

    const customNavigation = (screenName: CONSTANTS.SCREEN, params?: Record<string, string>) => {
        const language = (languageStore.currentLanguage as any) || CONSTANTS.SupportedLanguage.EN;
        let route = (CONSTANTS.ROUTES as any)?.[language]?.[screenName];

        if (params) {
            Object.keys(params).forEach((key) => {
                route = route.replace(`:${key}`, params[key]);
            });
        }

        navigation(route);
    };

    return customNavigation;
};

export default useCustomNavigation;

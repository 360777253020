import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { FunctionComponent, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useStores } from './Hooks';
import Router from './Router';
import CONSTANTS from './Constants';

import { GlobalLoadingContainer } from './Components';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App: FunctionComponent = observer(() => {
    const { i18n } = useTranslation();
    const { languageStore } = useStores();
    const queryClient = new QueryClient();

    useEffect(() => {
        //Triggered on language change or first render. Set appropriate local
        switch (languageStore.currentLanguage) {
            case CONSTANTS.SupportedLanguage.EN:
                i18n.changeLanguage('en');
                break;
            case CONSTANTS.SupportedLanguage.FR:
                i18n.changeLanguage('fr');
                break;
            case CONSTANTS.SupportedLanguage.ES:
                i18n.changeLanguage('es');
                break;
            case CONSTANTS.SupportedLanguage.IT:
                i18n.changeLanguage('it');
                break;
            case null:
                languageStore.setFirstRender();
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageStore.currentLanguage, i18n]);

    return (
        <div className="App">
            <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <GlobalLoadingContainer />
                </QueryClientProvider>
            </HelmetProvider>
        </div>
    );
});

export default App;

import { FC } from 'react';
import { SvgProps } from './SvgProps';

const TwitterIcon: FC<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18.901 1.15332H22.581L14.541 10.3433L24 22.8463H16.594L10.794 15.2623L4.156 22.8463H0.474L9.074 13.0163L0 1.15432H7.594L12.837 8.08632L18.901 1.15332ZM17.61 20.6443H19.649L6.486 3.24032H4.298L17.61 20.6443Z"
                fill="#1B1D1E"
            ></path>
        </svg>
    );
};

export default TwitterIcon;

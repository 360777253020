import { action, observable, makeAutoObservable } from 'mobx';
import CONSTANTS from '../Constants';

class LanguageStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable
    public currentLanguage: CONSTANTS.SupportedLanguage | null = null;

    @action
    setFirstRender() {
        if (localStorage.lang) {
            this.setLanguage(localStorage.lang as CONSTANTS.SupportedLanguage);
            return;
        }

        const browserLanguage = navigator.language.split('-')[0];

        this.setLanguage(
            CONSTANTS.SupportedLanguage[
                browserLanguage.toUpperCase() as keyof typeof CONSTANTS.SupportedLanguage
            ] || CONSTANTS.SupportedLanguage.EN
        );
    }

    @action
    setLanguage(lang: CONSTANTS.SupportedLanguage): void {
        this.currentLanguage = localStorage.lang = lang;
    }

    getSupportedLanguages(): { language: CONSTANTS.SupportedLanguage; label: string }[] {
        return [
            { language: CONSTANTS.SupportedLanguage.FR, label: 'FR' },
            { language: CONSTANTS.SupportedLanguage.EN, label: 'EN' },
            { language: CONSTANTS.SupportedLanguage.IT, label: 'IT' },
            { language: CONSTANTS.SupportedLanguage.ES, label: 'ES' },
        ];
    }
}

export default LanguageStore;

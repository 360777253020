import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../Constants';
import { useCustomNavigation } from '../../Hooks';

interface CustomLinkProps {
    destinationScreen: CONSTANTS.SCREEN;
    className?: string;
    onClick?: () => void;
    copydeckString: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({
    copydeckString,
    onClick,
    className,
    destinationScreen,
}) => {
    const { t } = useTranslation();
    const customNavigate = useCustomNavigation();

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            customNavigate(destinationScreen);
        }
    };

    return (
        <span onClick={handleClick} className={className}>
            {t(copydeckString as string)}
        </span>
    );
};

export default CustomLink;

import CONSTANTS from '../Constants';
import { OptionSEO } from '../Layout/DefaultLayout';
import { HomePage } from '../Screens';
import ErrorScreen from '../Screens/Error';
import LoginPage from '../Screens/Login';
import ProductDetail from '../Screens/ProductDetail';

export interface ROUTE {
    path: string;
    seo?: {
        fr: OptionSEO;
        en: OptionSEO;
    };
    children: () => JSX.Element;
    noLayout?: boolean;
    restricted?: {
        needLogin: boolean;
    };
}

export const ROUTER_CONFIG: ROUTE[] = [
    {
        path: CONSTANTS.SCREEN.HOME,
        seo: {
            fr: {
                title: 'Accueil',
                description:
                    "SpreeAI est l'endroit où votre expérience d'achat est associée à l'intelligence artificielle.",
                type: '',
                name: 'Home',
            },
            en: {
                title: 'Home',
                description: 'SpreeAI is where your shopping experience is combined with AI.',
                type: '',
                name: 'Home',
            },
        },
        children: HomePage,
        restricted: {
            needLogin: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.CATEGORY,
        seo: {
            fr: {
                title: 'Category',
                description:
                    "SpreeAI est l'endroit où votre expérience d'achat est associée à l'intelligence artificielle.",
                type: '',
                name: 'Home',
            },
            en: {
                title: 'Category',
                description: 'SpreeAI is where your shopping experience is combined with AI.',
                type: '',
                name: 'Home',
            },
        },
        children: HomePage,
        restricted: {
            needLogin: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.SUB_CATEGORY,
        seo: {
            fr: {
                title: 'Categorie',
                description:
                    "SpreeAI est l'endroit où votre expérience d'achat est associée à l'intelligence artificielle.",
                type: '',
                name: 'Home',
            },
            en: {
                title: 'Category',
                description: 'SpreeAI is where your shopping experience is combined with AI.',
                type: '',
                name: 'Home',
            },
        },
        children: HomePage,
        restricted: {
            needLogin: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.PRODUCT_DETAIL,
        seo: {
            fr: {
                title: 'Détail du Produit',
                description:
                    "Découvrez les détails du produit et l'intelligence artificielle qui améliore votre expérience d'achat sur SpreeAI.",
                type: '',
                name: 'Détail du Produit',
            },
            en: {
                title: 'Product Detail',
                description:
                    'Explore product and how AI enhances your shopping experience with SpreeAI.',
                type: '',
                name: 'Product Detail',
            },
        },
        children: ProductDetail,
        restricted: {
            needLogin: true,
        },
    },
    {
        path: CONSTANTS.SCREEN.LOGIN,
        seo: {
            fr: {
                title: 'Page de Connexion',
                description:
                    "Accédez à votre compte pour une expérience d'achat personnalisée avec SpreeAI.",
                type: '',
                name: 'Page de Connexion',
            },
            en: {
                title: 'Login Page',
                description:
                    'Access your account for a personalized shopping experience with SpreeAI.',
                type: '',
                name: 'Login Page',
            },
        },
        children: LoginPage,
        noLayout: true,
    },
    {
        path: '*',
        seo: {
            fr: {
                title: 'Page Non Trouvée',
                description: 'Page Non Trouvée',
                type: '',
                name: 'Page Non Trouvée',
            },
            en: {
                title: 'Page Not Found',
                description: 'Page Not Found',
                type: '',
                name: 'Page Not Found',
            },
        },
        children: ErrorScreen,
    },
];

import api from '../Utils/api';

interface DesignerDTO {
    id: string;
    name: string;
}

interface CategoryDTO {
    id: string;
    name: string;
    garment_count: number;
    sub_categories?: SubCategoryDTO[];
}

interface SubCategoryDTO {
    id: string;
    name: string;
    garment_count: number;
}

export interface ImageDTO {
    created: string;
    created_by: string;
    updated: string;
    updated_by: string;
    id: string;
    content_type: string;
    tag: string;
    url: string;
    size: number;
    height: number;
    width: number;
}

interface VariantDTO {
    color: string;
    size: string;
    price: number;
}

export interface GarmentDTO {
    id: string;
    title: string;
    designer: DesignerDTO;
    categories: CategoryDTO[];
    sub_categories: SubCategoryDTO[];
    description: string;
    thumbnail: ImageDTO;
    images: ImageDTO[];
    variants: VariantDTO[];
}

interface DesignersResponseDTO {
    designers: DesignerDTO[];
}

interface CategoriesResponseDTO {
    categories: CategoryDTO[];
}

interface GarmentsResponseDTO {
    garments: GarmentDTO[];
}

const PROTEA_BASE_URL = '/v1/protea';

const garmentService = {
    fetchCategories: async (): Promise<CategoriesResponseDTO> => {
        return api.get(`${PROTEA_BASE_URL}/categories`);
    },
    fetchDesigners: async (): Promise<DesignersResponseDTO> => {
        return api.get(`${PROTEA_BASE_URL}/designers`);
    },
    fetchAllGarments: async (): Promise<GarmentsResponseDTO> => {
        return api.get(`${PROTEA_BASE_URL}/garments`);
    },
    fetchGarment: async (garmentId: string): Promise<GarmentDTO> => {
        return api.get(`${PROTEA_BASE_URL}/garment/${garmentId}`);
    },
    fetchGarmentsByDesigner: async (designerId: string): Promise<GarmentsResponseDTO> => {
        return api.get(`${PROTEA_BASE_URL}/designer/${designerId}`);
    },
    fetchGarmentsByCategory: async (categoryId: string): Promise<GarmentsResponseDTO> => {
        return api.get(`${PROTEA_BASE_URL}/garments/category/${categoryId}`);
    },
    fetchGarmentsSubcategories: async (
        categoryId: string,
        subcategoryId: string
    ): Promise<GarmentsResponseDTO> => {
        return api.get(`/garments/category/${categoryId}/${subcategoryId}`);
    },
};

export default garmentService;

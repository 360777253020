import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import './index.scss';
import EyeIcon from '../../Icons/eye';
import SpreeaiLogo from '../../Icons/spreeai-logo';
import HandshakeIcon from '../../Icons/handshake';
import AssetsIcon from '../../Icons/assets';
import ComputerIcon from '../../Icons/computer';
import { authService } from '../../Services';
import { useCustomNavigation, useStores } from '../../Hooks';

import CONSTANTS from '../../Constants';
import { useTranslation } from 'react-i18next';
import { LoginSchema } from '../../Schema/LoginSchema';
import { theme } from '../../Style/theme';
import { toast } from 'react-toastify';
import { getUrlForEnvironment } from '../../Utils/environment';

type FormData = yup.InferType<typeof LoginSchema>;

const LoginPage = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(LoginSchema) as any,
    });

    const [showValue, setShowValue] = useState(false);
    const { userStore } = useStores();
    const customNavigate = useCustomNavigation();
    const { t } = useTranslation();

    const getForgotPasswordURL = () => getUrlForEnvironment(CONSTANTS.FORGOT_PASSWORD_URL);
    const getCreateAccountURL = () => getUrlForEnvironment(CONSTANTS.CREATE_ACCOUNT_URL);

    const onSubmit = async (data: FormData) => {
        try {
            const response = await authService.loginUser(data.email, data.password);
            if (response) {
                userStore.setUser(response);
                customNavigate(CONSTANTS.SCREEN.HOME);
            }
        } catch (error) {
            if (error instanceof Error) {
                const errorMessages = JSON.parse(error.message);
                if (
                    errorMessages.includes(
                        'Status Code: 500\n Source:service\n Message:user not found\n'
                    )
                ) {
                    toast.error(t('apiErrors.user_not_found') as string);
                } else if (errorMessages.includes('invalid login')) {
                    toast.error(t('apiErrors.invalid_login') as string);
                } else {
                    console.log(errorMessages);
                    errorMessages.forEach((msg: string) => toast.error(msg));
                }
            } else {
                toast.error('An unknown error occurred');
            }
        }
    };
    return (
        <div className="login container">
            <div className="login__container">
                <form className="login__form-container" onSubmit={handleSubmit(onSubmit)}>
                    <div className="login__form">
                        <h2 className="text-heading login__title">
                            {t('loginPage.login_spreeai')}
                        </h2>
                        <label htmlFor="email" className="login__label text-label">
                            {t('loginPage.email')}
                        </label>
                        <input
                            type="email"
                            id="email"
                            autoComplete="username"
                            className={`login__input login__email ${
                                errors.email ? 'login__input--error' : ''
                            }`}
                            {...register('email')}
                        />
                        <p className="login__error">
                            {errors.email ? t(`${errors.email.message}`) : ''}
                        </p>

                        <div className="login__password-container">
                            <label htmlFor="password" className="login__label text-label">
                                {t('loginPage.password')}
                            </label>
                            <div className="login__password-input-container">
                                <input
                                    type={showValue ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    className={`login__input login__password ${
                                        errors.password ? 'login__input--error' : ''
                                    }`}
                                    {...register('password')}
                                />
                                <EyeIcon
                                    className="login__toggle-password"
                                    onClick={() => setShowValue((prev) => !prev)}
                                    fill="#2B2B2B"
                                />
                            </div>
                            <p className="login__error">
                                {errors.password ? t(`${errors.password.message}`) : ''}
                            </p>
                        </div>

                        <button className="login__button text-bold">{t('loginPage.log_in')}</button>

                        <div className="login__forgot-password">
                            <a href={getForgotPasswordURL()}>{t('loginPage.forgot_password')}</a>
                        </div>
                    </div>
                    <a href={getCreateAccountURL()} className="login__create-account">
                        {t('loginPage.create_account')}
                    </a>
                </form>

                <div className="login__welcome-section">
                    <SpreeaiLogo className="login__welcome-logo" width={50} fill={theme.white} />
                    <h2 className="login__welcome-title text-heading">
                        {t('loginPage.welcome_back')}
                    </h2>
                    <ul className="login__feature-list">
                        <li className="login__feature-item">
                            <HandshakeIcon
                                className="login__feature-icon"
                                width={24}
                                height={24}
                                fill={theme.white}
                            />
                            <span className="login__feature-description">
                                <p className="text-bold  login__feature-title">
                                    {t('loginPage.partner_asks')}
                                </p>
                                <p className="text-small">
                                    {t('loginPage.partner_asks_description')}
                                </p>
                            </span>
                        </li>
                        <li className="login__feature-item">
                            <AssetsIcon
                                className="login__feature-icon"
                                width={24}
                                height={24}
                                stroke={theme.white}
                            />
                            <span className="login__feature-description">
                                <p className="text-bold login__feature-title">
                                    {t('loginPage.manage_assets')}
                                </p>
                                <p className="text-small">
                                    {t('loginPage.manage_assets_description')}
                                </p>
                            </span>
                        </li>
                        <li className="login__feature-item">
                            <ComputerIcon
                                className="login__feature-icon"
                                width={24}
                                height={24}
                                fill={theme.white}
                            />
                            <span className="login__feature-description">
                                <p className="text-bold  login__feature-title">
                                    {t('loginPage.view_demo')}
                                </p>
                                <p className="text-small">{t('loginPage.view_demo_description')}</p>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;

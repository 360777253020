import { CSSProperties, FC } from 'react';
import ProteaIcon from '../../Icons/protea';
import styles from './index.module.scss';

interface GarmentCardProps {
    firstImageUrl: string;
    secondImageUrl: string;
    title: string;
    subcategory: string;
    price: number;
    handleOnClick: () => void;
    style?: CSSProperties;
}
const GarmentCard: FC<GarmentCardProps> = ({
    firstImageUrl,
    secondImageUrl,
    subcategory,
    title,
    handleOnClick,
    style,
    price,
}) => {
    return (
        <div className={styles.card} style={style}>
            <ProteaIcon className={styles.icon} />
            <div className={styles.imageContainer} onClick={handleOnClick}>
                <img src={firstImageUrl} className={styles.firstImage} alt="First" />
                <img src={secondImageUrl} className={styles.secondImage} alt="Second" />
            </div>
            <div className={styles.info}>
                <div>
                    <h3 className={styles.info__title}>{title}</h3>
                    <p className={styles.info__subcategory}>{subcategory}</p>
                </div>
                <p className={styles.info__price}>{price}$</p>
            </div>
        </div>
    );
};

export default GarmentCard;

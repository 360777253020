import { useTranslation } from 'react-i18next';
import Copydeck from '../../i18n/Copydeck';

import './index.scss';
import InstagramIcon from '../../Icons/instagram';
import LinkedinIcon from '../../Icons/linkedin';
import TwitterIcon from '../../Icons/twitter';
import TiktokIcon from '../../Icons/tiktok';
import YoutubeIcon from '../../Icons/youtube';

const SOCIAL_MEDIAS = [
    {
        url: 'https://www.instagram.com/tryspreeai/',
        element: <InstagramIcon className="Footer__socialMediaIcon" />,
    },
    {
        url: 'https://www.linkedin.com/company/spreeai',
        element: <LinkedinIcon className="Footer__socialMediaIcon" />,
    },
    {
        url: 'https://twitter.com/spreeai',
        element: <TwitterIcon className="Footer__socialMediaIcon" />,
    },
    {
        url: 'https://www.tiktok.com/@spreeai',
        element: <TiktokIcon className="Footer__socialMediaIcon" />,
    },
    {
        url: 'https://www.youtube.com/channel/UCwqmYq9Cp-JE4iOU-LtRTPg',
        element: <YoutubeIcon className="Footer__socialMediaIcon" />,
    },
];

const Footer = () => {
    const { i18n } = useTranslation();
    const termsAndConditionsUrl =
        i18n.language === 'en'
            ? 'https://spreeai.com/terms-of-service'
            : 'https://spreeai.com/fr/term-de-service';
    const privacyPolicyUrl =
        i18n.language === 'en'
            ? 'https://spreeai.com/privacy-policy'
            : 'https://spreeai.com/fr/politique-de-confidentialite';

    return (
        <footer className="Footer">
            <div className="Footer__content container mx-auto">
                <div className="Footer__row Footer__row--1">
                    <span className="Footer__title">{Copydeck.protea}</span>
                    <a
                        href={termsAndConditionsUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="Footer__policyLinks Footer__policyLinks--terms text-bold"
                    >
                        {Copydeck.footerFooterTermsAndConditions}
                    </a>
                    <a
                        href={privacyPolicyUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="Footer__policyLinks text-bold"
                    >
                        {Copydeck.footerPrivacyPolicy}
                    </a>
                </div>
                <div className="Footer__row Footer__row--2">
                    <div className="Footer__allRightsReserved">
                        {Copydeck.footerAllRightsReserved}
                    </div>
                    <div className="Footer__socialMediaLinks">
                        {SOCIAL_MEDIAS.map((socialMedia) => (
                            <a
                                key={socialMedia.url}
                                href={socialMedia.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="Footer__socialMediaLink"
                            >
                                {socialMedia.element}
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            <div className="Footer__circle" />
        </footer>
    );
};

export default Footer;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import './index.scss';
import { Footer, Navbar } from '../../Components';

export interface OptionSEO {
    title: string;
    description: string;
    type: string;
    name: string;
}

interface DefaultLayoutProps {
    children: React.ReactNode;
    options?: OptionSEO;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children, options }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <main className="DefaultLayout">
            {options && (
                <Helmet>
                    {/* Standard metadata tags */}
                    <title>{options.title}</title>
                    <meta name="description" content={options.description} />
                    {/* End standard metadata tags */}

                    {/* Facebook tags */}
                    <meta property="og:type" content={options.type} />
                    <meta property="og:title" content={options.title} />
                    <meta property="og:description" content={options.description} />
                    {/* End Facebook tags */}

                    {/* Twitter tags */}
                    <meta name="twitter:creator" content={options.name} />
                    <meta name="twitter:card" content={options.type} />
                    <meta name="twitter:title" content={options.title} />
                    <meta name="twitter:description" content={options.description} />
                    {/* End Twitter tags */}
                </Helmet>
            )}
            <Navbar />
            <div className="DefaultLayout__content">{children}</div>
            <Footer />
        </main>
    );
};

export default DefaultLayout;

export const theme = {
    emerald: {
        400: '#50c878',
        500: '#2eb67d',
    },
    mercury: {
        200: '#e4e4e4',
        300: '#e5e5e6',
        400: '#adadad',
        500: '#8a9192',
        950: '#363636',
    },
    gray: {
        100: '#f0f0f0',
        200: '#f5f5f5',
        300: '#e0e0e0',
        900: '#121212',
        '900-translucent': '#121212bf',
    },
    white: '#ffffff',
    black: '#000000',
};

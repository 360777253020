import { action, autorun, makeAutoObservable } from 'mobx';
import CONSTANTS from '../Constants';

interface User {
    access_token: string;
    refresh_token: string;
    user_id: string;
}

const defaultUser: User = {
    access_token: '',
    refresh_token: '',
    user_id: '',
};

class UserStore {
    user: User = defaultUser;

    constructor() {
        makeAutoObservable(this);

        autorun(() => {
            if (this.user.access_token) {
                localStorage.setItem(
                    CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
                    this.user.access_token
                );
                localStorage.setItem(
                    CONSTANTS.LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
                    this.user.refresh_token
                );
            }
        });
    }

    @action
    setUser(user: Partial<User>) {
        this.user = { ...this.user, ...user };
    }

    @action
    clearUserData() {
        this.user = defaultUser;
    }

    get hasValidToken(): boolean {
        return Boolean(this.user.access_token);
    }
}

export default UserStore;

import React from 'react';
import { Container } from 'aurelia-dependency-injection';
import { StoresContext } from '../Contexts';
import { GlobalStores } from '../Contexts/StoresContext';

const useStores = (): GlobalStores => {
    const storesContext = Container.instance.get(StoresContext);
    return React.useContext(storesContext.context);
};

export default useStores;

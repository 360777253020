import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import Logo from '../../Assets/Img/Color_Spree_Logo.webp';
import { useStores } from '../../Hooks';
import './index.scss';

const GlobalLoadingContainer: FunctionComponent = observer(() => {
    const { loadingStore } = useStores();
    return (
        <div className={`GlobalLoadingContainer ${loadingStore.isLoading() ? 'loading' : ''}`}>
            {loadingStore.isLoading() && (
                <div>
                    <img loading="lazy" className="logo" src={Logo} alt="SpreAI Logo" />
                </div>
            )}
        </div>
    );
});

export default GlobalLoadingContainer;

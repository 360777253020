import { FC } from 'react';
import { SvgProps } from './SvgProps';

const ComputerIcon: FC<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="24"
            height="28"
            viewBox="0 0 28 28"
            fill="#ffffff"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.74707 19.5781H11.2227L10.9854 21.1689H10.0537C9.61426 21.1689 9.25391 21.5293 9.25391 21.9775C9.25391 22.4082 9.61426 22.7773 10.0537 22.7773H17.9375C18.377 22.7773 18.7373 22.4082 18.7373 21.9775C18.7373 21.5293 18.377 21.1689 17.9375 21.1689H17.0059L16.7686 19.5781H22.2441C23.8438 19.5781 24.6787 18.7695 24.6787 17.1348V7.00098C24.6787 5.36621 23.8438 4.55762 22.2441 4.55762H5.74707C4.14746 4.55762 3.3125 5.36621 3.3125 7.00098V17.1348C3.3125 18.7695 4.14746 19.5781 5.74707 19.5781ZM5.39551 15.4912C5.14062 15.4912 5.02637 15.3945 5.02637 15.1221V7.10645C5.02637 6.50879 5.28125 6.27148 5.86133 6.27148H22.1299C22.71 6.27148 22.9648 6.50879 22.9648 7.10645V15.1221C22.9648 15.3945 22.8506 15.4912 22.5957 15.4912H5.39551ZM14 18.6904C13.5254 18.6904 13.1299 18.3037 13.1299 17.8291C13.1299 17.3545 13.5254 16.959 14 16.959C14.4658 16.959 14.8613 17.3545 14.8613 17.8291C14.8613 18.3037 14.4658 18.6904 14 18.6904Z"
                fill={props.fill}
            ></path>
        </svg>
    );
};

export default ComputerIcon;

import { FC } from 'react';
import { SvgProps } from './SvgProps';

const UserAccountIcon: FC<SvgProps> = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="22"
            {...props}
            fill="none"
            className="hidden lg:block"
        >
            <path
                fill={props.fill}
                fillRule="evenodd"
                d="M6.013 2.495a6.5 6.5 0 1 1 4.974 12.01 6.5 6.5 0 0 1-4.974-12.01ZM10.158.163a8.5 8.5 0 1 0-3.316 16.674A8.5 8.5 0 0 0 10.158.163ZM2 21a1 1 0 1 1-2 0v-1a2 2 0 0 1 2-2h13a2 2 0 0 1 2 2v1a1 1 0 1 1-2 0v-1H2v1Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default UserAccountIcon;

import React from 'react';
import { inject } from 'aurelia-dependency-injection';
import { LanguageStore, LoadingStore, UserStore } from '../Stores';

export interface GlobalStores {
    languageStore: LanguageStore;
    loadingStore: LoadingStore;
    userStore: UserStore;
}

@inject(LanguageStore, LoadingStore, UserStore)
export class StoresContext {
    context: React.Context<GlobalStores>;

    constructor(languageStore: LanguageStore, loadingStore: LoadingStore, userStore: UserStore) {
        this.context = React.createContext({
            languageStore,
            loadingStore,
            userStore,
        });
    }
}

import { useEffect } from 'react';
import CONSTANTS from '../Constants';
import useCustomNavigation from './use-custom-navigation';
import useStores from './use-stores';

const useAuthRedirect = () => {
    const { userStore } = useStores();
    const customNavigate = useCustomNavigation();

    useEffect(() => {
        if (!localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
            customNavigate(CONSTANTS.SCREEN.LOGIN);
            localStorage.clear();
            userStore.clearUserData();
        }
        //eslint-disable-next-line
    }, [window.location.pathname]);
};

export default useAuthRedirect;

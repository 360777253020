import CONSTANTS from '../Constants';
import { getUrlForEnvironment } from './environment';

const getApiBase = (): string => getUrlForEnvironment(CONSTANTS.API_URL);

const defaultHeaders = {
    'Content-Type': 'application/json',
};

interface ApiError {
    source: string;
    message: string;
}

const handleResponse = async <T>(response: Response) => {
    if (!response.ok) {
        const error = await response.json();
        const errorMessages = error.errors?.map((err: ApiError) => err.message) || [
            'An error occurred',
        ];
        console.log(errorMessages, 'errors');
        throw new Error(JSON.stringify(errorMessages));
    }
    return response.json() as Promise<T>;
};

const api = {
    get: async <T>(endpoint: string): Promise<T> => {
        const response = await fetch(`${getApiBase()}${endpoint}`, {
            method: 'GET',
            headers: defaultHeaders,
        });
        return handleResponse<T>(response);
    },
    post: async <T>(endpoint: string, body: any): Promise<T> => {
        const response = await fetch(`${getApiBase()}${endpoint}`, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(body),
        });
        return handleResponse<T>(response);
    },
};

export default api;
